<template>
    <div class="company-list" v-if="isAdmin">
        <template v-for="(item, index) in companyList" :key="item.id">
            <div :class="[item.selected? 'selected': '']" @click="onClick(index)">{{item.name}}</div>
        </template>
    </div>
</template>

<script lang="ts">
    import { defineComponent, ref, reactive, toRaw, toRefs, unref, computed } from 'vue';
    import Company from "@/request/Company";
    import {SetupContext} from "@vue/runtime-core";
    import SessionStorageKeys from "@/common/SessionStorageKeys";
    
    export default defineComponent({
        name: 'el-company-list',
        setup(props: any, ctx: SetupContext) {
            const companyList = ref();
    
            const isAdmin = ref<boolean>(false);
            if (sessionStorage.getItem(SessionStorageKeys.type) === 'admin') {
                isAdmin.value = true;
                Company.getList({}).then((body: any) => {
                    companyList.value = body.data;
                    onClick(0);
                })
            } else {
                ctx.emit('on-click', {
                    index: 0,
                    item: {id: sessionStorage.getItem(SessionStorageKeys.companyId)}
                });
            }

            ctx.emit('on-load', {
                isAdmin: isAdmin.value
            })
            
            const onClick = (ind: number) => {
                companyList.value.forEach((item: any, index: number) => {
                    if (index === ind) {
                        item.selected = true;
                    } else {
                        item.selected = false;
                    }
                });
                ctx.emit('on-click', {
                    index: ind,
                    item: companyList.value[ind]
                });
            }
            
            return {
                isAdmin,
                companyList,
                onClick
            }
        }
    })
    
</script>

<style lang="less">

    .company-list {
        height: 100%;
        width: 150px;
        >div {
            padding: 8px 15px;
            cursor: pointer;
        }
        .selected {
            background-color: rgb(230,	246,	255	);
            color: rgb(36,	147,	255	);
        }
    }
    
</style>