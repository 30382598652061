import {Request} from "@/Request";
import SessionStorageKeys from "@/common/SessionStorageKeys";

class Company extends Request {

    public path: string = '/company';

    public companyId = sessionStorage.getItem(SessionStorageKeys.companyId);

    getList = (data: any) => {
        return this.formPost(`${this.path}/getList`, data)
    };


    companyConsultList = () => {
        return this.get(`${this.path}/consult/list`)
    }

}

export default new Company();